<template>
  <div class="wrapper">
    <div
      class="banner"
      data-aos="fade-down"
      data-aos-delay="100"
    >
      <div class="text">
        <h1
          data-aos="zoom-in-up"
          data-aos-delay="100"
        >
          {{ $t('convenio.CONVENIO_DE_PRÁCTICAS') }}
        </h1>
        <div
          class="hr"
          data-aos="zoom-in-up"
          data-aos-delay="100"
        />
        <h2
          data-aos="zoom-in-up"
          data-aos-delay="100"
        >
          {{ $t('convenio.ponemos_fácil') }}
        </h2>
        <b-button
          v-smooth-scroll="{ duration: 1500, offset: -100 }"
          data-aos="zoom-in-up"
          data-aos-delay="100"
          :to="{ path: '#formConvenio' }"
        >
          {{ $t('convenio.descubre') }}
        </b-button>
      </div>
    </div>
    <b-container
      id="formulario"
      class="mt-5 mb-5"
    >
      <b-row>
        <b-col
          lg="6"
          class="right-text"
        >
          <h2
            data-aos="fade-down"
            data-aos-duration="200"
            @click="show"
          >
            {{ $t('convenio.CONVENIO_DE_PRÁCTICAS_EN') }}
          </h2>
          <h1
            style=""
            data-aos="fade-down"
            data-aos-duration="100"
          >
            {{ $t('convenio.Empresas') }}
          </h1>
          <p>
            {{ $t('convenio.En_AICAD_las_prácticas') }}
          </p>
        </b-col>
        <b-col
          class="contact-form agent"
          lg="6"
          data-aos="fade-in"
          data-aos-delay="100"
          style="padding:0"
        >
          <Form
            v-if="!sentSuccess"
            class="contact3-form validate-form"
            @submit.prevent="onSubmit"
          />
          <div
            v-else-if="sentSuccess"
            class="send-success"
          >
            <h2>{{ $t('common_contact_form.thanks_message') }}</h2>
            <h3 style="color:#fff;">
              {{ $t('common_contact_form.will_contact') }}
            </h3>
            <div
              class="contact-info text-center mt-4"
              style="color:#fff"
            >
              <h4>Puedes contactarme por</h4>
              <div class="contact-nfo">
                <font-awesome-icon
                  icon="phone"
                  class="phone-icon"
                />
                <a
                  href="tel:+34928354668"
                  style="color:#fff"
                >+34 928 354 668</a>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container style="margin-top: 100px; margin-bottom: 150px;">
      <b-row>
        <b-col
          lg="4"
          data-aos="fade-down"
          data-aos-delay="100"
        >
          <div class="text-container">
            <h1>{{ $t('convenio.CURSOS') }}</h1>
            <h2>{{ $t('convenio.UNIVERSITARIOS') }}</h2>
            <p style="font-size: 14px;color: #888;">
              Practicas Curriculares en colaboración con Universidades
            </p>
          </div>
        </b-col>
        <b-col
          lg="4"
          data-aos="fade-down"
          data-aos-delay="100"
        >
          <div class="text-container">
            <h1>{{ $t('convenio.CONVENIO') }}</h1>
            <h2>
              {{ $t('convenio.DE_EMPRESA') }}
            </h2>
          </div>
        </b-col>
        <b-col
          lg="4"
          data-aos="fade-down"
          data-aos-delay="100"
        >
          <div class="text-container">
            <h1>{{ $t('convenio.CONVENIO') }}</h1>
            <h2>{{ $t('convenio.DE_PRÁCTICA') }}</h2>
            <p style="font-size: 14px;color: #888;">
              Convenios de Prácticas Profesional: de 6 a 12 meses
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div
      class="skew-grey"
      data-aos="fade-right"
      data-aos-delay="100"
    />
    <div
      class="section1"
      data-aos="fade-right"
      data-aos-delay="100"
    >
      <b-container>
        <b-row>
          <b-col lg="6">
            <iframe
              class="youtube"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/mRNxM1ao1IU"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="0"
            />
          </b-col>
          <b-col lg="6">
            <h1>
              {{ $t('convenio.Tienes_la_posibilidad') }}
            </h1>
            <p style="margin-bottom:0;">
              {{ $t('convenio.Nos_distinguimos') }}
            </p>
          </b-col>
          <b-col
            lg="12"
            style="padding:3rem 0"
          >
            <h1>
              {{ $t('convenio.tenemos_un_fuerte') }}
            </h1>
            <p style="text-align:center;">
              {{ $t('convenio.Lo_que_muchas_personas') }}
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="skew-grey-cc" />
    <div
      class="skew-blue"
      data-aos="fade-left"
      data-aos-delay="100"
    />
    <div
      class="section2"
      data-aos="fade-left"
      data-aos-delay="100"
    >
      <img
        loading="lazy"
        src="../assets/img/convenio_practicas/whatsapp.png"
        alt="aicad-whatsapp-number"
        style="width: 50px;"
      >
      <p style="margin-top: 10px;font-size: 20pt;">
        {{ $t('convenio.Necesitas_información') }}
      </p>
      <a
        href="tel:617783460"
        style="font-size: 18pt;"
      >
        <p style="font-weight: bolder;">
          {{ $t('convenio.tel') }}
        </p>
      </a>
      <a
        class="btn whatsapp-btn"
        target="_blank"
        href="https://api.whatsapp.com/send?phone=+34617783460%20&amp;text=%C2%A1Hola%21%20Quiero%20tomar%20informaci%C3%B3n%20sobre%20sus%20servicios.%20%C2%BFUsted%20me%20podr%C3%ADa%20ayudar%20por%20favor%3F%0A%0ARecib%C3%AD%20tu%20n%C3%BAmero%20de%20https%3A%2F%2Fwebsincoste.com%2Fconvenio-de-practicas"
      ><font-awesome-icon :icon="['fab', 'whatsapp']" /> WhatsApp</a>
    </div>
    <div
      class="skew-blue-cc"
      data-aos="fade-left"
      data-aos-delay="100"
    />
    <b-container
      class="section-black"
      data-aos="fade-right"
      data-aos-delay="100"
    >
      <b-row>
        <b-col
          lg="6"
          md="12"
        >
          <h1>{{ $t('convenio.Periodo_de_Prácticas') }}</h1>
          <p>
            {{ $t('convenio.este_punto') }}
          </p>
        </b-col>
        <b-col
          lg="6"
          md="12"
        >
          <iframe
            class="youtube"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/C_0JINuvnKw"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="0"
            data-aos="fade-left"
            data-aos-delay="100"
          />
          <div
            class="box"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <h3>
              <span style="font-weight: bolder">{{ $t('convenio.Precio') }}<br></span>{{ $t('convenio.descuento') }}
            </h3>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div
      style="background: #009090;padding:40px 0px"
      data-aos="fade-right"
      data-aos-delay="100"
    >
      <b-container>
        <div class="flex-item-wrapper">
          <div class="flex-item">
            <img
              loading="lazy"
              alt="convenio-de-practicas-1"
              src="../assets/img/convenio_practicas/icons/convenio-de-practicas1.png"
            >
            <div>
              <p>{{ $t('convenio.CURSO_ONLINE') }}</p>
            </div>
          </div>
          <div class="flex-item">
            <img
              loading="lazy"
              alt="convenio-de-practicas-2"
              src="../assets/img/convenio_practicas/icons/convenio-de-practicas2.png"
            >
            <div>
              <p>{{ $t('convenio.Responsabilidad') }}</p>
            </div>
          </div>
          <div class="flex-item">
            <img
              loading="lazy"
              alt="convenio-de-practicas-3"
              src="../assets/img/convenio_practicas/icons/convenio-de-practicas3.png"
            >
            <div>
              <p>{{ $t('convenio.Asistencia') }}</p>
            </div>
          </div>
          <div class="flex-item">
            <img
              loading="lazy"
              alt="convenio-de-practicas-4"
              src="../assets/img/convenio_practicas/icons/convenio-de-practicas4.png"
            >
            <div>
              <p>{{ $t('convenio.Gestión') }}</p>
            </div>
          </div>
          <div class="flex-item">
            <img
              loading="lazy"
              alt="convenio-de-practicas-5"
              src="../assets/img/convenio_practicas/icons/convenio-de-practicas5.png"
            >
            <div>
              <p>{{ $t('convenio.Tutorías') }}</p>
            </div>
          </div>
        </div>
        <div class="flex-item-wrapper">
          <div class="flex-item">
            <img
              loading="lazy"
              alt="convenio-de-practicas-6"
              src="../assets/img/convenio_practicas/icons/convenio-de-practicas6.png"
            >
            <div>
              <p>{{ $t('convenio.Acceso') }}</p>
            </div>
          </div>
          <div class="flex-item">
            <img
              loading="lazy"
              alt="convenio-de-practicas-7"
              src="../assets/img/convenio_practicas/icons/convenio-de-practicas7.png"
            >
            <div>
              <p>{{ $t('convenio.Diploma') }}</p>
            </div>
          </div>
          <div class="flex-item">
            <img
              loading="lazy"
              alt="convenio-de-practicas-8"
              src="../assets/img/convenio_practicas/icons/convenio-de-practicas8.png"
            >
            <div>
              <p>{{ $t('convenio.Hasta6meses') }}</p>
            </div>
          </div>
          <div class="flex-item">
            <img
              loading="lazy"
              alt="convenio-de-practicas-9"
              src="../assets/img/convenio_practicas/icons/convenio-de-practicas9.png"
            >
            <div>
              <p>{{ $t('convenio.Coste_seguro') }}</p>
            </div>
          </div>
          <div class="flex-item">
            <img
              loading="lazy"
              alt="convenio-de-practicas1-0"
              src="../assets/img/convenio_practicas/icons/convenio-de-practicas10.png"
            >
            <div>
              <p>{{ $t('convenio.Horario_máximo') }}</p>
            </div>
          </div>
        </div>
      </b-container>
    </div>
    <div
      id="muchas"
      class="section4"
    >
      <b-container>
        <h1
          data-aos="fade-down"
          data-aos-delay="100"
        >
          {{ $t('convenio.Algunos_cursos') }}
        </h1>
        <h2
          data-aos="fade-down"
          data-aos-delay="100"
        >
          {{ $t('convenio.puedes_realizar') }}
        </h2>
        <div
          class="white-box"
          data-aos="fade-in"
          data-aos-delay="100"
        >
          <b-row>
            <b-col lg="3">
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="bigstock-young-office"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/bigstock-young-office-businessman-worki-206185894_dN76n8IfVr.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Curso Superior en Finanzas, Contabilidad de Costes y Cuentas Anuales
                </div>
              </div>
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="new-project-1"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/new-projecdt-1.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Curso Superior de Diseño Web
                </div>
              </div>
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="bigstock-1"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/bigstock--215784994.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Certificación en Habilidades Directivas
                </div>
              </div>
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="bigstock-2"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=480&amp;h=270&amp;zc=1&amp;src=1/bigstock--223469515.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Curso Superior en Marketing Digital
                </div>
              </div>
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="bigstock-3"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/bigstock-empty-search-bar-web-site-ur-228467092.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Técnico en Seguridad Informática
                </div>
              </div>
            </b-col>
            <b-col lg="3">
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="new-project-2"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/new-project11-1.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Certificación Profesional en Gestión y Tratamientos de Aguas ETAP y EDAR
                </div>
              </div>
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="new-project-3"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/new-project.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Certificación en Counseling y Terapia Gestalt
                </div>
              </div>
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="new-project-4"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/new-23123project-1.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Curso Intensivo Inglés B1. Nivel Oficial Consejo Europeo
                </div>
              </div>
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="new-project-5"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/new-projessct-1.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Certificación Internacional en Dirección y Gestión de Proyectos
                </div>
              </div>
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="new-project-6"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/new-project-36.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Técnicas Administrativas de Aprovisionamiento Jefe de Compras
                </div>
              </div>
            </b-col>
            <b-col lg="3">
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="new-project-7"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/new-project-10.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Experto en Fotografía Digital y Retoque Fotográfico
                </div>
              </div>
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="new-project-8"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/new-project-38.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Ofimática: Aplicaciones Informáticas de Gestión
                </div>
              </div>
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="new-project-9"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/new-projeadasdct-1.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Técnico en gestión aduanera del comercio internacional
                </div>
              </div>
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="bigstock-always-occupied"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/bigstock-always-occupied-close-up-of-s-225583279.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Curso Superior de Gestión de Stock, Distribución Comercial y Transporte
                </div>
              </div>
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="técnico-en-prevencin-de-riesgos"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&src=1/tcnico-en-prevencin-de-riesgos-laborales-en-el-comercio.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Técnico en prevención de riesgos laborales en el comercio
                </div>
              </div>
            </b-col>
            <b-col lg="3">
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="new-project-10"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/new-projesct-1.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Especialista TIC en Programación de Páginas Web con ASP NET 4 en C Sharp + Javascript
                </div>
              </div>
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="new-project-11"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/new-project_7MNh0t5OBX.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Gestión Administrativa para Asesoramiento de Productos y Servicios Financieros
                </div>
              </div>
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="bigstock-using-technologies"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/bigstock-using-technologies-clever-exp-230219668.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Técnico Profesional en Creación de Webs con Joomla + SEO Profesional
                </div>
              </div>
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="new-project-12"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&amp;src=1/new-prodject-2.jpg"
                  >
                </div>
                <div class="convenio-course-text">
                  Técnico Profesional en Diseño Web Avanzado con HTML5 y CSS3
                </div>
              </div>
              <div class="convenio-course-item">
                <div class="cci-image-wrapper">
                  <img
                    loading="lazy"
                    alt="expertos-en-gestión-laboral"
                    class="convenio-course-image"
                    src="https://www.websincoste.com/timthumb.php?w=320&src=1/04---expertos-en-gestin-laboral-actualizacin-2020-min.png"
                  >
                </div>
                <div class="convenio-course-text">
                  Experto en gestión laboral (actualizado a 2020)
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <h3
          class="mt-4"
          data-aos="fade-in"
          data-aos-delay="100"
        >
          {{ $t('convenio.rellenar') }}
        </h3>
      </b-container>
    </div>
    <b-container
      id="formConvenio"
      class=""
    >
      <b-row>
        <b-col lg="12">
          <div class="form-wrapper-section clearfix">
            <form
              v-if="!sentSuccess2"
              id="contactForm"
              class="contact3-form validate-form"
              @submit.prevent="onSubmit2"
            >
              <h2>Rellena el siguiente formulario para recibir el catálogo y toda la información sobre el convenio de prácticas:</h2>
              <b-row>
                <b-col lg="6">
                  <div class="form-group">
                    <label>¿Cual es tu nombre?</label>
                    <b-input
                      v-model="form2.name"
                      type="text"
                      placeholder="Tu nombre"
                      class="form-control"
                      required
                    />
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="form-group">
                    <label>Tu mejor mail</label>
                    <b-input
                      v-model="form2.email"
                      type="email"
                      placeholder="Tu mail"
                      class="form-control"
                      required
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6">
                  <div class="form-group">
                    <label>¿A qué numero te llamamos?</label>
                    <b-input
                      v-model="form2.number"
                      type="tel"
                      placeholder="Su teléfono"
                      class="form-control"
                      required
                    />
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="form-group">
                    <label>¿De dónde eres?</label>
                    <b-input
                      v-model="form2.donde"
                      type="text"
                      placeholder="¿De dónde eres?"
                      class="form-control"
                      required
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6">
                  <div class="form-group">
                    <label>Elige tu mejor opción:</label>
                    <select
                      v-model="form2.mejoropcion"
                      class="form-control"
                      required
                    >
                      <option
                        value=""
                        disabled
                        selected
                      >
                        Seleccione su situación
                      </option>
                      <option value="No se que elegir, quiero el catalogo">
                        No se que elegir, quiero el catalogo
                      </option>
                      <option value="Soy una empresa y tengo el candidato">
                        Soy una empresa y tengo el candidato
                      </option>
                      <option value="Quiero que me llaméis porque tengo dudas">
                        Quiero que me llaméis porque tengo dudas
                      </option>
                      <option value="Busco una solución para trabajar 8 horas al día">
                        Busco una solución para trabajar 8 horas al día
                      </option>
                      <option value="Curso Superior de Marketing Digital">
                        Curso Superior de Marketing Digital
                      </option>
                      <option value="Técnico Profesional en Diseño Web Profesional con Dreamweaver">
                        Técnico Profesional en Diseño Web Profesional con Dreamweaver
                      </option>
                      <option value="Técnico Profesional en Creación de Portales Web con Joomla + SEO Profesional">
                        Técnico Profesional en Creación de Portales Web con Joomla + SEO Profesional
                      </option>
                      <option value="Certificación Profesional en Gestión y Tratamientos de Aguas ETAP y EDAR">
                        Certificación Profesional en Gestión y Tratamientos de Aguas ETAP y EDAR
                      </option>
                      <option value="Certificación en Counseling y Terapia Gestalt">
                        Certificación en Counseling y Terapia Gestalt
                      </option>
                      <option value="Curso Intensivo Inglés B1. Nivel Oficial Consejo Europeo">
                        Curso Intensivo Inglés B1. Nivel Oficial Consejo Europeo
                      </option>
                      <option value="Certificación Internacional en Dirección y Gestión de Proyectos">
                        Certificación Internacional en Dirección y Gestión de Proyectos
                      </option>
                      <option value="Gestión Administrativa del Comercio Internacional">
                        Gestión Administrativa del Comercio Internacional
                      </option>
                      <option value="Técnico en Periodismo Digital">
                        Técnico en Periodismo Digital
                      </option>
                      <option value="Curso Superior Online Ofimática 2013">
                        Curso Superior Online Ofimática 2013
                      </option>
                      <option value="Técnico de Almacenaje y Gestión Logística en la Empresa">
                        Técnico de Almacenaje y Gestión Logística en la Empresa
                      </option>
                      <option value="Técnico Profesional en Coaching Ejecutivo y Empresarial">
                        Técnico Profesional en Coaching Ejecutivo y Empresarial
                      </option>
                      <option value="Especialista TIC en Programación de Webs con ASP NET4, C Sharp + Javascript">
                        Especialista TIC en Programación de Webs con ASP NET4, C Sharp + Javascript
                      </option>
                      <option value="Curso Superior de Contabilidad Nivel Experto">
                        Curso Superior de Contabilidad Nivel Experto
                      </option>
                    </select>
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="form-group">
                    <label>Necesitaré un seguro RC para:</label>
                    <div class="input-group">
                      <label class="mr-2"><input
                        v-model="form2.segurorcpara"
                        type="radio"
                        name="radio0"
                        value="España (incluido)"
                        required
                      > España (incluido)</label>
                      <label><input
                        v-model="form2.segurorcpara"
                        type="radio"
                        name="radio0"
                        value="Fuera, según ámbito territorial"
                        required
                      > Fuera, según ámbito territorial</label>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div class="form-group">
                <label>
                  <input
                    id="RGPD_OPTIN"
                    v-model="form2.checked"
                    type="checkbox"
                    class="check"
                    required
                  > {{ $t('convenio.He_leído') }}
                  <a
                    target="_blank"
                    href="/aviso-legal-privacidad/"
                  >{{ $t('convenio.políticas_de_privacidad') }}</a>
                </label>
                <ul class="list">
                  <li><b>Información básica sobre Protección de Datos:</b></li>
                  <li><b>Responsable:</b> Aicad Business School</li>
                  <li><b>Finalidad:</b> Gestión del envío de información solicitada.</li>
                  <li><b>Legitimación:</b> Consentimiento del interesado.</li>
                  <li><b>Derechos:</b> Tiene derecho a Acceder, rectificar y suprimir los datos, así como otros derechos.</li>
                  <li>
                    <b>Información adicional:</b> Puede consultar la información adicional y detallada <a
                      target="_blank"
                      href="/aviso-legal-privacidad/"
                    >AQUI.</a>
                  </li>
                </ul>
              </div>
              <div class="form-group">
                <button
                  id="send-btn"
                  class="sib-form-block__button"
                  type="submit"
                >
                  Envíame el Catalogo
                </button>
                <h3
                  v-if="sentFailed2"
                  style="color: #ff9ca6;"
                >
                  Error: ¡Inténtalo de nuevo!
                </h3>
              </div>
            </form>
            <div
              v-if="sentSuccess2"
              class="send-success"
            >
              <h2>{{ $t('common_contact_form.thanks_message') }}</h2>
              <h3>{{ $t('common_contact_form.will_contact') }}</h3>
              <div
                class="contact-info text-center mt-4"
                style="color:#fff"
              >
                <h4>Puedes contactarme por</h4>
                <div class="contact-nfo">
                  <font-awesome-icon
                    icon="phone"
                    class="phone-icon"
                  />
                  <a
                    href="tel:+34928354668"
                    style="color:#fff"
                  >+34 928 354 668</a>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <div class="section5">
        <h1
          class="italic"
          data-aos="fade-right"
          data-aos-delay="100"
        >
          {{ $t('convenio.Aún_tienes') }}<span class="bold">{{ $t('convenio.dudas') }}</span>
        </h1>
        <p
          data-aos="fade-in"
          data-aos-delay="100"
        >
          {{ $t('convenio.disposición') }}
        </p>
      </div>
    </b-container>
    <b-container class="section6">
      <h1>{{ $t('convenio.FAQ') }}</h1>
      <b-row>
        <b-col lg="6">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="100"
          >
            <b-col lg="11">
              <h2>{{ $t('convenio.significa') }}</h2>
              <template v-if="show1 === true">
                <h3>
                  {{ $t('convenio.documento') }}
                </h3>
              </template>
            </b-col>
            <b-col lg="1">
              <font-awesome-icon
                class="icon"
                icon="plus"
                style="margin: 0 auto; color: #585858;"
                @click="show1 = !show1"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="100"
          >
            <b-col lg="10">
              <h2>{{ $t('convenio.pasa_cuando') }}</h2>
              <template v-if="show2 === true">
                <h3>
                  {{ $t('convenio.universidad') }}
                </h3>
              </template>
            </b-col>
            <b-col lg="1">
              <font-awesome-icon
                class="icon"
                icon="plus"
                style="margin: 0 auto; color: #585858;"
                @click="show2 = !show2"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="100"
          >
            <b-col lg="11">
              <h2>{{ $t('convenio.realizar') }}</h2>
              <template v-if="show3 === true">
                <h3>
                  {{ $t('convenio.relacionada') }}
                </h3>
              </template>
            </b-col>
            <b-col lg="1">
              <font-awesome-icon
                class="icon"
                icon="plus"
                style="margin: 0 auto; color: #585858;"
                @click="show3 = !show3"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="100"
          >
            <b-col lg="10">
              <h2>
                {{ $t('convenio.Qué_debe_hacer_la_empresa') }}
              </h2>
              <template v-if="show4 === true">
                <h3>
                  {{ $t('convenio.financian') }}
                </h3>
              </template>
            </b-col>
            <b-col lg="1">
              <font-awesome-icon
                class="icon"
                icon="plus"
                style="margin: 0 auto; color: #585858;"
                @click="show4 = !show4"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="100"
          >
            <b-col lg="11">
              <h2>{{ $t('convenio.becarios') }}</h2>
              <template v-if="show5 === true">
                <h3>{{ $t('convenio.cotización') }}</h3>
              </template>
            </b-col>
            <b-col lg="1">
              <font-awesome-icon
                class="icon"
                icon="plus"
                style="margin: 0 auto; color: #585858;"
                @click="show5 = !show5"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="100"
          >
            <b-col lg="10">
              <h2>{{ $t('convenio.horario') }}</h2>
              <template v-if="show6 === true">
                <h3>{{ $t('convenio.trabajar') }}</h3>
              </template>
            </b-col>
            <b-col lg="1">
              <font-awesome-icon
                class="icon"
                icon="plus"
                style="margin: 0 auto; color: #585858;"
                @click="show6 = !show6"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="100"
          >
            <b-col lg="11">
              <h2>{{ $t('convenio.debes_realizar') }}</h2>
              <template v-if="show7 === true">
                <h3>
                  {{ $t('convenio.sabemos_que_para_ti') }}
                </h3>
              </template>
            </b-col>
            <b-col lg="1">
              <font-awesome-icon
                class="icon"
                icon="plus"
                style="margin: 0 auto; color: #585858;"
                @click="show7 = !show7"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row
            class="box"
            data-aos="zoom-in-up"
            data-aos-delay="100"
          >
            <b-col lg="10">
              <h2>{{ $t('convenio.cotizado') }}</h2>
              <template v-if="show8 === true">
                <h3>{{ $t('convenio.situación') }}</h3>
              </template>
            </b-col>
            <b-col lg="1">
              <font-awesome-icon
                class="icon"
                icon="plus"
                style="margin: 0 auto; color: #585858;"
                @click="show8 = !show8"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <div class="background" />
    <b-container class="section7">
      <h1
        data-aos="zoom-in-up"
        data-aos-delay="100"
      >
        {{ $t('convenio.Estás_decidido') }}
      </h1>
      <h2
        data-aos="zoom-in-up"
        data-aos-delay="100"
      >
        {{ $t('convenio.firmar') }}
      </h2>
      <h3
        data-aos="fade-in"
        data-aos-delay="100"
      >
        {{ $t('convenio.realizar_las_practicas') }}
      </h3>
      <b-button
        v-smooth-scroll="{ duration: 1500, offset: -100 }"
        class="btn"
        data-aos="zoom-in-up"
        data-aos-delay="100"
        :to="{ path: '#formulario' }"
      >
        {{ $t('convenio.Formulario') }}
      </b-button>
    </b-container>
    <div
      class="section8"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="800"
    >
      <h1>{{ $t('convenio.Testimonios') }}</h1>
    </div>
    <b-container class="mt-5 mb-5">
      <b-row>
        <b-col
          lg="4"
          md="6"
          sm="12"
          data-aos="fade-down"
          data-aos-delay="100"
        >
          <iframe
            class="youtube"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/F4QOqU_zFbQ"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </b-col>
        <b-col
          lg="4"
          md="6"
          sm="12"
          data-aos="fade-down"
          data-aos-delay="100"
        >
          <iframe
            class="youtube"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/6X-BK6puwvc"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </b-col>
        <b-col
          lg="4"
          md="12"
          sm="12"
          data-aos="fade-down"
          data-aos-delay="100"
        >
          <iframe
            class="youtube"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/WkM5XiMGwq0"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </b-col>
      </b-row>
    </b-container>
    <b-container class="section10">
      <div
        class="sponsor-title"
        style="text-align:center"
      >
        Empresas que han confiado en nosotros
      </div>
      <div
        class="sponsor-slider container"
        style="margin-bottom:50px;margin-top:30px;"
      >
        <carousel
          :per-page="8"
          :mouse-drag="true"
          :loop="true"
          :scroll-per-page="false"
          :pagination-enabled="false"
          :navigation-enabled="true"
          :autoplay="true"
          :autoplay-timeout="5000"
          :min-swipe-distance="4"
          :per-page-custom="[[320, 1], [576, 2], [768, 4], [1199, 8]]"
        >
          <slide
            v-for="member in team"
            :key="member.name"
          >
            <img
              loading="lazy"
              alt="Team member"
              class="photo"
              :src="getImgUrl(member.image)"
            >
          </slide>
        </carousel>
      </div>
    </b-container>
    <LegalModal />
  </div>
</template>

<script>
import Form from '@/components/IntershipForm.vue';
import { Carousel, Slide } from 'vue-carousel';
import LegalModal from '@/components/Reusable/LegalModal.vue';
export default {
    name: 'ConvenioPracticas',
    components: {
        Form,
        Carousel,
        Slide,
        LegalModal
    },
    metaInfo() {
        return {
            title: 'Cursos con convenio de prácticas - Aicad Business School',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: '¿Estás interesado en firmar un Convenio de prácticas? Accede ahora a cientos de cursos con convenio de prácticas para mejorar tu futuro laboral!'
                },
                {
                    name: 'keyword',
                    content: 'convenio de prácticas, cursos con convenio de practicas, cursos prácticas, cursos convenios prácticas, convenio práctica, curso online para practicas'
                },
                {
                    name: 'robots',
                    content: 'index, follow'
                },
                {
                    name: 'language',
                    content: 'Spanish'
                },
                {
                    name: 'revisit-after',
                    content: '10 days'
                },
                {
                    name: 'og:image',
                    property: 'og:image',
                    content: 'https://www.websincoste.com/assets/img/convenio-practicas.jpg'
                }
            ]
        };
    },
    data() {
        return {
            team: [
                {
                    image: '59701club-baloncesto-gran-canaria.jpg'
                },
                {
                    image: '93308elbaul.jpg'
                },
                {
                    image: '138952fundacion-universitaria-las-palmas.jpg'
                },
                {
                    image: '146856impactmedia.jpg'
                },
                {
                    image: '174020diamond-resorts-international.jpg'
                },
                {
                    image: '207177cacesa.jpg'
                },
                {
                    image: '257192aprendum.jpg'
                },
                {
                    image: '368276canarias7.jpg'
                },
                {
                    image: '393742fundacion-manpowergroup.jpg'
                },
                {
                    image: '522395grupo-prisa.jpg'
                },
                {
                    image: '568730hotel-costa-calero.jpg'
                },
                {
                    image: '590924television-canaria.jpg'
                },
                {
                    image: '637012movistar.jpg'
                },
                {
                    image: '677389grupo-intercom.jpg'
                },
                {
                    image: '701252ulpgc.jpg'
                },
                {
                    image: '910237letsbonus.jpg'
                },
                {
                    image: '1515106736-1024x512.png'
                },
                {
                    image: 'adsamurai.png'
                },
                {
                    image: 'agencia EFE.png'
                },
                {
                    image: 'atrapalo.png'
                },
                {
                    image: 'coca-cola-logo.png'
                },
                {
                    image: 'descarga.png'
                },
                {
                    image: 'DSSI.png'
                },
                {
                    image: 'edreams.png'
                },
                {
                    image: 'elecam-group-logo.png'
                },
                {
                    image: 'emagister.png'
                },
                {
                    image: 'Ifema.png'
                },
                {
                    image: 'intercos.png'
                },
                {
                    image: 'Kyocera_logo.png'
                },
                {
                    image: 'LNFS.png'
                },
                {
                    image: 'loewe-logo.png'
                },
                {
                    image: 'LOGO-marca-nunsys-rosa-01.png'
                },
                {
                    image: 'mobile world capital.png'
                },
                {
                    image: 'octocamvision.png'
                },
                {
                    image: 'orange logo.png'
                },
                {
                    image: 'palladium hotels-logo.png'
                },
                {
                    image: 'Panasonic (1).png'
                },
                {
                    image: 'ProColombia_Logo_Vertical.png'
                },
                {
                    image: 'prontopiso.png'
                },
                {
                    image: 'tech-data-squarelogo.png'
                },
                {
                    image: 'telefonica-logo.png'
                },
                {
                    image: 'Tryp_MelillaPuerto.png'
                },
                {
                    image: 'unilever.png'
                },
                {
                    image: 'wallenius-wilhelmsen-ocean-vector-logo.png'
                }
            ],
            show1: false,
            show2: false,
            show3: false,
            show4: false,
            show5: false,
            show6: false,
            show7: false,
            show8: false,
            form: {
                name: '',
                email: '',
                number: '',
                option: '',
                checked: 'He leído y acepto las políticas de privacidad'
            },
            form2: {
                name: '',
                email: '',
                number: '',
                donde: '',
                mejoropcion: '',
                segurorcpara: '',
                checked: 'He leído y acepto las políticas de privacidad'
            },
            status: 'not_accepted',
            sentSuccess: false,
            sentFailed: false,
            sentSuccess2: false,
            sentFailed2: false,
            modalVisible: false
        };
    },
    mounted: function() {
        document.getElementById('contactForm').style.display = 'none';
    },
    beforeDestroy: function() {
        document.getElementById('contactForm').style.display = 'block';
    },
    methods: {
        getImgUrl(pic) {
            return require('../assets/img/sponsor/' + pic);
        },
        onSubmit() {
            event.preventDefault();
            this.axios
                .post('https://www.websincoste.com/index.php', {
                    _nonce: 'f9d8d3d927baca5e53d95c32b41f6f00',
                    action: 'send_universal_form',
                    serialize: [{ name: 'Form Type', value: 'Convenio Practicas Form' }, { name: 'Nombre', value: this.form.name }, { name: 'Email', value: this.form.email }, { name: 'Telefono', value: this.form.number }, { name: 'Opcion 1', value: this.form.option }, { name: 'Reference Link', value: window.location.href }, { name: 'Aceptación Privacidad RGDP:', value: this.form.checked }]
                })
                .then(response => {
                    if (response.status === 200) {
                        this.sentSuccess = true;
                    }
                })
                .catch(error => {
                    this.sentFailed = true;
                    this.sentSuccess = false;
                    console.log(error);
                });
        },
        onSubmit2() {
            event.preventDefault();
            this.axios
                .post('https://www.websincoste.com/index.php', {
                    _nonce: 'f9d8d3d927baca5e53d95c32b41f6f00',
                    action: 'send_universal_form',
                    serialize: [{ name: 'Form Type', value: 'Convenio Practicas Catalogue Form' }, { name: 'Nombre', value: this.form2.name }, { name: 'Email', value: this.form2.email }, { name: 'Telefono', value: this.form2.number }, { name: '¿De dónde eres?', value: this.form2.donde }, { name: 'Elige tu mejor opción:', value: this.form2.mejoropcion }, { name: 'Necesitaré un seguro RC para:', value: this.form2.segurorcpara }, { name: 'Reference Link', value: window.location.href }, { name: 'Aceptación Privacidad RGDP:', value: this.form2.checked }]
                })
                .then(response => {
                    if (response.status === 200) {
                        this.sentSuccess2 = true;
                    }
                })
                .catch(error => {
                    this.sentFailed2 = true;
                    this.sentSuccess2 = false;
                    console.log(error);
                });
        },
        show() {
            this.$modal.show('hello-world');
        },
        hide() {
            this.$modal.hide('hello-world');
        }
    }
};
</script>
<style scoped lang="scss">
.flex-item-wrapper {
	display: flex;
}
.flex-item {
	width: 20%;
	padding: 10px;
	p {
		color: #fff;
		text-align: center;
		font-size: 0.9rem;
		font-weight: bold;
	}
}
@media (max-width: 425px) {
	.flex-item-wrapper {
		flex-wrap: wrap;
	}
	.flex-item {
		width: 100%;
		padding: 10px;
		text-align: center;
	}
}
.privacidad p {
	font-size: 13px;
}
h1 {
	text-align: center;
}
.form-wrapper-section .send-success {
	position: static;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	transform: none;
}

.form-wrapper-section .send-success h2 {
	font-size: 28pt;
	font-weight: bold;
}

.form-wrapper-section {
	padding: 30px;
	background-color: #ddd;
	max-width: 80%;
	margin: 50px auto;
	box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
	@media only screen and (max-width: 768px) {
		max-width: unset;
		margin: unset;
	}
}

.form-wrapper-section > form {
	margin: 0 auto;
}

.form-wrapper-section h2 {
	font-size: 18pt;
	text-align: center;
	color: #444;
}

#app .form-wrapper-section #send-btn {
	background-color: #666;
	border: 1px solid #333;
}

.convenio-course-item .cci-image-wrapper {
	height: 120px;
	overflow: hidden;
	opacity: 1;
	transition: 0.3s ease all;
}

.convenio-course-item .convenio-course-text {
	font-weight: bold;
	height: 7em;
	overflow: hidden;
	padding: 10px;
}

.convenio-course-item {
	border: 1px solid #ddd;
	margin-bottom: 10px;
	transition: 0.3s ease all;
}

.convenio-course-item:hover {
	background-color: #ddd;
}

.convenio-course-item:hover .cci-image-wrapper {
	opacity: 0.8;
}

.whatsapp-btn {
	background-color: #25d366;
	color: #fff;
	font-size: 18pt;
}
.whatsapp-btn:hover {
	color: #fff;
	background-color: #0f9240;
}
.agente.contact-form .send-success {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	height: 100%;
}
.banner {
	background-image: url('../assets/img/convenio_practicas/Banner-convenio-practicas.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left;
	height: 800px;
	text-align: right;
	padding-right: 50px;
	padding-left: 50px;
	@media only screen and (max-width: 424px) {
		background: rgb(0, 130, 195);
		background: linear-gradient(90deg, rgba(0, 0, 0, 0.3617822128851541) 0%, rgba(69, 187, 198, 0.5242471988795518) 54%, rgba(197, 224, 195, 1) 100%), url('../assets/img/convenio_practicas/Banner-convenio-practicas.jpg');
		padding-right: 15px;
		padding-left: 15px;
		background-repeat: no-repeat;
	}
	@media only screen and (max-width: 768px) {
		background: rgb(0, 130, 195);
		background: linear-gradient(90deg, rgba(0, 0, 0, 0.3617822128851541) 0%, rgba(69, 187, 198, 0.5242471988795518) 54%, rgba(197, 224, 195, 1) 100%), url('../assets/img/convenio_practicas/Banner-convenio-practicas.jpg');
		background-repeat: no-repeat;
	}
	@media only screen and (max-width: 1366px) {
		background-size: 100%;
	}
	.text {
		max-width: 500px;
		width: 100%;
		float: right;
		position: relative;
		top: 200px;
		@media only screen and (max-width: 1366px) {
			right: 0;
		}
		h1 {
			font-family: LatoWebBlackItalic, sans-serif;
			font-size: 2.5em;
			color: white;
			@media only screen and (max-width: 424px) {
				font-size: 1.7em;
				color: white;
			}
		}

		.hr {
			height: 2px;
			width: 100%;
			background-color: white;
			margin-bottom: 10px;
		}

		h2 {
			font-family: LatoWebMedium, sans-serif;
			font-size: 1.5rem;
			color: white;
		}

		.btn {
			border: none;

			background-color: white;
			font-family: LatoWebMedium, sans-serif;
			width: 100%;
			color: #008bf2;
		}
	}
}

.contact-form {
	margin-top: -130px;
	background-color: rgba(0, 0, 0, 0.8);
	text-align: left;
	padding: 20px;
	@media only screen and (max-width: 768px) {
		margin-bottom: 50px;
	}

	h2 {
		width: 100%;
		font-family: LatoWebBold, sans-serif;
		color: whitesmoke;
		font-size: 1.5rem;
	}

	.field {
		width: 100%;
		border-radius: 0px;
		margin-bottom: 10px;
	}

	.drop {
		width: 100%;
		border-radius: 0px;
		margin-bottom: 10px;
		background-color: white !important;
	}

	p {
		text-align: left;
		color: whitesmoke;
	}

	.check {
		text-align: left;
		color: white;
	}

	.list {
		text-align: left;
		color: whitesmoke;
		list-style: square;
	}

	@media only screen and (max-width: 424px) {
		margin-top: -50px;
	}
}

.right-text {
	h1 {
		color: #009090;
		font-family: LatoWebBlack, sans-serif;
		font-size: 5rem;
		letter-spacing: 2px;
		@media only screen and (max-width: 424px) {
			font-size: 3rem;
		}
	}

	h2 {
		font-family: LatoWebLightItalic, sans-serif;
	}

	p {
		font-family: LatoWebSemiboldItalic, sans-serif;
		font-size: 1.5rem;
		color: #3d3d3d;
	}
}

.text-container {
	text-align: center;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	padding: 50px;
	border-top: #2a89b9 10px solid;
	background-color: white;
	-webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

	h1 {
		font-family: LatoWebBlack, sans-serif;
		font-size: 1.5rem;
		color: #4b4b4b;
	}

	h2 {
		font-family: LatoWebLight, sans-serif;
		font-size: 1.2rem;
	}

	@media only screen and (max-width: 424px) {
		padding: 50px;
		margin-top: 0;
		width: 100%;
	}
	@media only screen and (max-width: 776px) {
		margin-top: 0;
		width: 100%;
	}
}

.skew-grey {
	width: 100%;
	height: 100px;
	position: relative;
	left: 0px;
	margin-top: -100px;
	background: linear-gradient(to right bottom, transparent 49%, #4e4e4e 50%);
}

.skew-grey-cc {
	width: 100%;
	height: 100px;
	position: relative;
	left: 0px;
	margin-top: -10px;
	background: linear-gradient(to left top, transparent 49%, #4e4e4e 50%);
}

.section1 {
	background-color: #4e4e4e;
	padding: 20px;
	text-align: left;

	h1 {
		font-family: LatoWebBold, sans-serif;
		font-size: 1.2rem;
		color: white;
	}

	p {
		font-family: LatoWebLight, sans-serif;
		color: white;
	}
}

.youtube {
	width: 100%;
	position: relative;
	z-index: 51;
}

.skew-blue {
	width: 100%;
	height: 100px;
	position: relative;
	left: 0px;
	margin-top: -100px;
	background: linear-gradient(to right bottom, transparent 49%, #3c84bf 50%);
}

.skew-blue-cc {
	width: 100%;
	height: 100px;
	position: relative;
	left: 0px;
	background: linear-gradient(to left top, transparent 49%, #3c84bf 50%);
}

.section2 {
	text-align: center;
	background-color: #3c84bf;
	padding: 20px;

	p {
		font-family: LatoWebLight, sans-serif;
		color: white;
	}
}

.section3 {
	margin-top: 50px;
	margin-bottom: 100px;
	text-align: center;

	h1 {
		align-content: center;
		font-family: LatoWebMedium, sans-serif;
		font-size: 1.2rem;
		text-transform: capitalize;
		color: #525252;
		@media only screen and (max-width: 768px) {
			text-align: center !important;
		}
	}

	.align-row {
		padding: 20px;
		margin-bottom: 20px;
	}

	img {
		width: 70px;
		@media only screen and (max-width: 768px) {
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}
}

.section4 {
	background-image: url('../assets/img/convenio_practicas/background-girl.png');
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: auto;
	text-align: center;
	padding: 20px;
	@media only screen and (max-width: 424px) {
		padding: 50px 0 50px 0;
	}

	h1 {
		align-content: center;
		font-family: LatoWebBlack, sans-serif;
		font-size: 5vh;

		letter-spacing: 7px;
		color: white;
		@media only screen and (max-width: 430px) {
			font-size: 5vh;
		}
	}

	h2 {
		align-content: center;
		font-family: LatoWebLight, sans-serif;
		font-size: 3vh;

		color: white;
	}

	h3 {
		padding-left: 100px;
		padding-right: 100px;
		text-align: left;
		font-family: LatoWebLight, sans-serif;
		font-size: 1.5vh;

		color: white;
		@media only screen and (max-width: 776px) {
			padding-left: 50px;
			padding-right: 50px;
			font-size: 2vh;
		}
		@media only screen and (max-width: 364px) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.white-box {
		text-align: center;
		width: 100%;
		height: 50%;
		margin: 0 auto;
		padding: 30px;
		border-top: #2a89b9 10px solid;
		background-color: white;
		-webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

		h1 {
			font-family: LatoWebBlack, sans-serif;
			font-size: 1rem;
			color: #4b4b4b;
			letter-spacing: 2px;
			text-align: left;
			margin-bottom: 20px;

			&:hover {
				color: black;
			}
		}

		h2 {
			font-family: LatoWebLight, sans-serif;
			font-size: 1.2rem;
		}

		@media only screen and (max-width: 424px) {
			padding: 15px;
			margin-top: 0;
			width: 100%;
		}
		@media only screen and (max-width: 776px) {
			margin-top: 0;
			width: 100%;
		}
	}
}

.section5 {
	margin: 100px auto 100px auto;
	padding: 50px;
	background-color: whitesmoke;
	height: auto;
	width: 60%;
	text-align: center;
	transition: 0.3s ease;

	.bold {
		font-family: LatoWebBlack, sans-serif;
	}

	.italic {
		font-family: LatoWebLightItalic, sans-serif;
	}

	p {
		font-family: LatoWebLight, sans-serif;
		color: #525252;
	}

	@media only screen and (max-width: 776px) {
		width: 90%;
		padding: 20px;
		h1 {
			text-align: center;
		}
	}
}

.section6 {
	text-align: center;

	h1 {
		font-family: LatoWebBlack, sans-serif;
		font-size: 8vh;
		letter-spacing: 7px;
		color: #525252;
	}

	h2 {
		font-size: 1.1rem;
		color: #000000;
	}

	h3 {
		font-weight: 300;
		font-size: 1rem;
		color: #555;
	}

	.box {
		margin: 0 auto;
		margin-bottom: 50px;
		justify-content: center;
		padding: 20px;
		text-align: left;
		width: 95%;
		background-color: whitesmoke;
		height: auto;
		transition: 0.3s ease;

		.icon {
			transition: 0.3s ease;

			&:hover {
				cursor: pointer;
				transform: scale(1.2);
			}
		}
	}
}

.background {
	background-color: whitesmoke;
	height: 700px;
	width: 100%;
}
.section7 {
	position: relative;
	text-align: center;
	padding: 20px;
	margin-top: -600px;
	margin-bottom: 100px;

	h1 {
		align-content: center;
		font-family: LatoWebBlack, sans-serif;
		font-size: 7vh;

		letter-spacing: 7px;
		color: #525252;
		@media only screen and (max-width: 430px) {
			font-size: 5vh;
		}
		@media only screen and (min-width: 1900px) {
			font-size: 4rem;
		}
	}

	h2 {
		align-content: center;
		font-family: LatoWebBlack, sans-serif;
		font-size: 4vh;

		color: #525252;
	}

	h3 {
		padding-left: 200px;
		padding-right: 200px;
		text-align: center;
		font-family: LatoWebMediumItalic, sans-serif;
		font-size: 16px;

		color: #474747;
		@media only screen and (max-width: 776px) {
			padding-left: 50px;
			padding-right: 50px;
		}
		@media only screen and (max-width: 364px) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.btn {
		border: none;

		background-color: white;
		font-family: LatoWebBlack, sans-serif;
		width: 20%;
		min-width: 200px;
		color: black;
		transition: 0.3s ease;

		&:hover {
			cursor: pointer;
			transform: scale(1.2);
		}
	}
}

.section8 {
	margin-top: -100px;
	background-image: url('../assets/img/convenio_practicas/background-flesh.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 500px;
	text-align: center;

	h1 {
		padding-top: 280px;
		align-content: center;
		font-family: LatoWebBlack, sans-serif;
		font-size: 8vh;

		letter-spacing: 7px;
		color: white;
		@media only screen and (max-width: 430px) {
			font-size: 5vh;
		}
	}
}

.section9 {
	margin-top: 100px;

	p {
		font-family: LatoWebBold, sans-serif;
		color: black;
	}
}

.section10 {
	.sponsor-title {
		color: #525252;
		font-size: 1.7rem;
	}
	.VueCarousel-dot {
		width: 5px !important;
		height: 5px !important;
	}
}
.section-black {
	background: linear-gradient(90deg, rgba(0, 130, 195, 1) 0%, rgba(69, 187, 198, 1) 54%, rgba(197, 224, 195, 1) 100%);
	height: auto;
	padding: 100px;
	@media only screen and (max-width: 430px) {
		margin-top: -100px;
		padding: 80px 20px 20px 20px;
	}

	h1 {
		align-content: center;
		text-align: right;
		font-family: LatoWebBlack, sans-serif;
		font-size: 4rem;

		letter-spacing: 7px;
		color: white;
		@media only screen and (max-width: 430px) {
			font-size: 5vh;
		}
	}

	.box {
		h3 {
			padding: 15px;
			background: linear-gradient(90deg, rgba(90, 139, 166, 1) 0%, rgba(192, 255, 241, 0.48503151260504207) 100%);
			align-content: center;
			text-align: right;
			font-family: LatoWebMedium, sans-serif;
			font-size: 1.8rem;
			color: white;
		}

		@media only screen and (max-width: 1024px) {
			background: transparent;
			left: 0;
			h3 {
			}
		}
	}

	p {
		align-content: center;
		text-align: right;
		font-family: LatoWebLight, sans-serif;
		font-size: 1.2rem;
		color: white;
	}
}
.send-success {
	width: 90%;
	color: white;
	position: absolute;
	top: 50%;
	text-align: center;
	left: 50%;
	transform: translate(-50%, -50%);
	h2 {
		font-size: 4rem;
	}
}
</style>
