<template>
  <div
    class="sib-form"
    style="text-align: center;
         background-color: #EFF2F7;                                 "
  >
    <div
      id="sib-form-container"
      class="sib-form-container"
    >
      <div
        id="error-message"
        class="sib-form-message-panel"
        style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-width:px; border-color:#ff4949;max-width:540px; border-width:px;"
      >
        <div class="sib-form-message-panel__text sib-form-message-panel__text--center">
          <svg
            viewBox="0 0 512 512"
            class="sib-icon sib-notification__icon"
          >
            <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
          </svg>
          <span class="sib-form-message-panel__inner-text">
            No hemos podido validar tu solicitud, envíanos un correo a tutorias@aicad si el error persiste.
          </span>
        </div>
      </div>
      <div />
      <div
        id="success-message"
        class="sib-form-message-panel"
        style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#085229; background-color:#e7faf0; border-radius:3px; border-width:px; border-color:#13ce66;max-width:540px; border-width:px;"
      >
        <div class="sib-form-message-panel__text sib-form-message-panel__text--center">
          <svg
            viewBox="0 0 512 512"
            class="sib-icon sib-notification__icon"
          >
            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" />
          </svg>
          <span class="sib-form-message-panel__inner-text">
            ¡Gracias por cumplimentar el formulario! Comprueba tu correo electrónico para más detalles.
          </span>
        </div>
      </div>
      <div />
      <div
        id="sib-container"
        class="sib-container--large sib-container--vertical"
        style="text-align:center; background-color:rgba(255,255,255,1); max-width:540px; border-radius:3px; border-width:1px; border-color:#C0CCD9; border-style:solid;"
      >
        <form
          id="sib-form"
          method="POST"
          action="https://sibforms.com/serve/MUIEAD6l2YYCK4e65t1q6ni-0w9KBpMgWr3SDc8WVak1KPK103pDFSKAp7jlK9ceuyDe1HO1Z2pJhNhzMSQfZZI2HuL6gRr8gqPYA-jl_CefSQciLyU0RzN7yvxXBR5RvwufImDgSeddIcLLPtykEGlINgy6hdCUbSq5F_C-2L522C-JWSF13GizgUZIeXri8JqieLVosuSrgc5Q"
          data-type="subscription"
        >
          <div style="padding: 10px 0;">
            <div
              class="sib-form-block"
              style="text-align:center; font-weight:700; font-family:&quot;Helvetica&quot;, sans-serif; color:#3C4858; background-color:transparent; border-width:px;"
            >
              <p style="font-size:2rem">
                Convenio de prácticas
              </p>
            </div>
          </div>
          <div style="padding: 10px 0;">
            <div
              class="sib-form-block"
              style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#3C4858; background-color:transparent; border-width:px;"
            >
              <div class="sib-text-form-block">
                <p style="font-size:16px;text-align:center;">
                  Déjanos tus datos y te informamos sobre el convenio de prácticas:
                </p>
              </div>
            </div>
          </div>
          <b-row style="margin:0">
            <b-col
              lg="6"
              style="padding:0"
            >
              <div style="padding: 10px 0;">
                <div class="sib-input sib-form-block">
                  <div class="form__entry entry_block">
                    <div class="form__label-row ">
                      <div class="entry__field">
                        <input
                          id="NOMBRE"
                          class="input"
                          maxlength="200"
                          type="text"
                          name="NOMBRE"
                          autocomplete="off"
                          placeholder="¿Cuál es tu nombre?"
                          data-required="true"
                          required
                        >
                      </div>
                    </div>

                    <label
                      class="entry__error entry__error--primary"
                      style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-width:px; border-color:#ff4949;"
                    />
                  </div>
                </div>
              </div>
            </b-col>
            <b-col
              lg="6"
              style="padding:0"
            >
              <div style="padding: 10px 0;">
                <div class="sib-input sib-form-block">
                  <div class="form__entry entry_block">
                    <div class="form__label-row ">
                      <div class="entry__field">
                        <input
                          id="EMAIL"
                          class="input"
                          type="text"
                          name="EMAIL"
                          autocomplete="off"
                          placeholder="Tu mejor email"
                          data-required="true"
                          required
                        >
                      </div>
                    </div>
                    <label
                      class="entry__error entry__error--primary"
                      style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-width:px; border-color:#ff4949;"
                    />
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <div style="padding: 10px 0;">
            <div class="sib-sms-field sib-form-block">
              <div class="form__entry entry_block">
                <div class="form__label-row ">
                  <div class="sib-sms-input-wrapper">
                    <div
                      class="sib-sms-input"
                      data-placeholder="Teléfono"
                      data-required="1"
                      data-country-code="ES"
                      data-value=""
                    >
                      <div class="entry__field">
                        <select
                          class="input"
                          name="SMS__COUNTRY_CODE"
                          data-required="true"
                        >
                          <option value="+34">
                            +34 ES
                          </option>
                        </select>
                      </div>
                      <div
                        class="entry__field"
                        style="width: 100%"
                      >
                        <input
                          id="SMS"
                          type="tel"
                          class="input"
                          name="SMS"
                          autocomplete="off"
                          placeholder="Teléfono"
                          data-required="true"
                          required
                        >
                      </div>
                    </div>
                    <div class="sib-sms-tooltip">
                      <div class="sib-sms-tooltip__box">
                        El campo SMS debe contener entre 6 y 19 cifras e incluir el prefijo del país sin «+» ni «0» delante (ej.: 34xxxxxxxxxxx para España)
                      </div>
                      <span class="sib-sms-tooltip__icon">?</span>
                    </div>
                  </div>
                </div>
                <label
                  class="entry__error entry__error--primary"
                  style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-width:px; border-color:#ff4949;"
                />
                <label
                  class="entry__error entry__error--secondary"
                  style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-width:px; border-color:#ff4949;"
                />
                <label
                  class="entry__specification"
                  style="font-size:12px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#8390A4; border-width:px;"
                >
                  Déjanos tu teléfono y despejaremos tus dudas.
                </label>
              </div>
            </div>
          </div>
          <div style="padding: 10px 0;">
            <div
              class="sib-select sib-form-block"
              data-required="true"
            >
              <div class="form__entry entry_block">
                <div class="form__label-row ">
                  <label
                    class="entry__label"
                    style="font-size:16px; text-align:left; font-weight:700; font-family:&quot;Helvetica&quot;, sans-serif; color:#3c4858; border-width:px;"
                    for="SITUACION_PRACTICAS"
                    data-required="*"
                  >
                    ¿Cuál es tu situación? (requerido)
                  </label>
                  <div class="entry__field">
                    <select
                      id="SITUACION_PRACTICAS"
                      class="input"
                      name="SITUACION_PRACTICAS"
                      data-required="true"
                    >
                      <option
                        class="sib-menu__item"
                        value="1"
                      >
                        Busco firmar un convenio de prácticas
                      </option>
                      <option
                        class="sib-menu__item"
                        value="2"
                      >
                        Soy una empresa y tengo el candidato
                      </option>
                      <option
                        class="sib-menu__item"
                        value="3"
                      >
                        No tengo la empresa donde realizar las prácticas
                      </option>
                      <option
                        class="sib-menu__item"
                        value="4"
                      >
                        Quiero hablar por teléfono
                      </option>
                    </select>
                  </div>
                </div>
                <label
                  class="entry__error entry__error--primary"
                  style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-width:px; border-color:#ff4949;"
                />
              </div>
            </div>
          </div>
          <div style="padding-top: 16px;">
            <div
              class="sib-optin sib-form-block"
              data-required="true"
            >
              <div class="form__entry entry_mcq">
                <div class="form__label-row ">
                  <div class="entry__choice">
                    <label>
                      <input
                        id="RGPD_OPTIN"
                        type="checkbox"
                        class="input_replaced"
                        value="He leído y acepto las políticas de privacidad"
                        name="RGPD_OPTIN"
                        required
                      >
                      <span class="checkbox checkbox_tick_positive" />
                      <span style="font-size:14px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#3C4858; background-color:transparent; border-width:px;">He leído y acepto las políticas de privacidad</span> <span
                        data-required="*"
                        class="entry__label entry__label_optin"
                      />
                    </label>
                  </div>
                </div>
                <label
                  class="entry__error entry__error--primary"
                  style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-width:px; border-color:#ff4949;"
                />
              </div>
            </div>
          </div>
          <div style="padding-bottom:16px">
            <div
              class="sib-form-block"
              style="font-size:12px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#3C4858; background-color:transparent; border-width:px;"
            >
              <div class="sib-text-form-block">
                <p>
                  Puedes &nbsp;consultar la información adicional y detallada sobre Protección de Datos en nuestra <a
                    style="cursor:pointer; color: rgb(4, 101, 245);"
                    @click="showModal()"
                  >política de privacidad</a>.&nbsp;
                </p>
                <p><strong>Responsable del tratamiento</strong>: Aicad Business School S.L.</p>
                <p><strong>Finalidad de los datos</strong>: Envío de boletines de noticias y ofertas.</p>
                <p><strong>Almacenamiento de los datos</strong>: Base de datos alojada en la UE, y con transferencias internacionales de gestión a terceros paises.</p>
                <p>
                  <strong>Derechos</strong>: En cualquier momento puedes <a
                    style="cursor:pointer; color: rgb(4, 101, 245);"
                    @click="showModal()"
                  >limitar, recuperar y borrar tu información</a>.
                </p>
              </div>
            </div>
          </div>
          <div style="padding: 10px 0;">
            <div
              class="g-recaptcha"
              data-sitekey="6LfnieUUAAAAAI24S17coet-5wco2Dgi5IWFB1Q_"
              data-callback="invisibleCaptchaCallback"
              data-size="invisible"
              onclick="executeCaptcha"
            />
          </div>
          <div style="padding: 10px 0;">
            <div
              class="sib-form-block"
              style="text-align: left"
            >
              <button
                class="sib-form-block__button sib-form-block__button-with-loader"
                style="font-size:16px; text-align:left; font-weight:700; font-family:&quot;Helvetica&quot;, sans-serif; color:#FFFFFF; background-color:#3E4857; border-radius:3px; border-width:0px;"
                form="sib-form"
                type="submit"
              >
                <svg
                  class="icon clickable__icon progress-indicator__icon sib-hide-loader-icon"
                  viewBox="0 0 512 512"
                >
                  <path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                </svg>
                Necesito Información
              </button>
            </div>
          </div>
          <input
            type="text"
            name="email_address_check"
            value=""
            class="input--hidden"
          >
          <input
            type="hidden"
            name="locale"
            value="es"
          >
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    mounted: function() {
        this.addScript('https://sibforms.com/forms/end-form/build/main.js', 'script1');
        this.addScript('https://www.google.com/recaptcha/api.js?hl=es', 'script2');
        this.addCss('https://assets.sendinblue.com/component/form/2ef8d8058c0694a305b0.css');
        this.addCss('https://assets.sendinblue.com/component/clickable/b056d6397f4ba3108595.css');
        this.addCss('https://assets.sendinblue.com/component/progress-indicator/f86d65a4a9331c5e2851.css');
        this.addCss('https://sibforms.com/forms/end-form/build/sib-styles.css');
    },
    methods: {
        addCss: function(fileName) {
            var link = document.createElement('link');
            link.type = 'text/css';
            link.rel = 'stylesheet';
            link.href = fileName;
            document.head.appendChild(link);
        },
        addScript: function(fileName, id) {
            setTimeout(function() {
                var script = document.createElement('script');
                script.setAttribute('src', fileName);
                script.setAttribute('class', id);
                document.body.appendChild(script);
            }, 8000);
        },
        showModal: function() {
            this.$modal.show('hello-world');
        },
        hide() {
            this.$modal.hide('hello-world');
        }
    }
};
window.REQUIRED_CODE_ERROR_MESSAGE = 'Elija un código de país';
window.EMAIL_INVALID_MESSAGE = window.SMS_INVALID_MESSAGE = 'La información que ha proporcionado no es válida. Compruebe el formato del campo e inténtelo de nuevo.';
window.REQUIRED_ERROR_MESSAGE = 'Este campo no puede quedarse vacío. ';
window.GENERIC_INVALID_MESSAGE = 'La información que ha proporcionado no es válida. Compruebe el formato del campo e inténtelo de nuevo.';
window.translation = {
    common: {
        selectedList: '{quantity} lista seleccionada',
        selectedLists: '{quantity} listas seleccionadas'
    }
};

</script>
<style lang="scss">
  #sib-form div {
    color: #fff;
  }
  #sib-form div,
  #sib-form h1,
  #sib-form p,
  #sib-form label,
  #sib-form span {
    color: #fff !important;
  }
  .sib-form {
    background-color: transparent !important;
    padding: 16px 12px !important;
  }
  #sib-container {
    background-color: rgba(0, 0, 0, 0) !important;
    border: none !important;
    padding: 10px !important;
  }
  #sib-form option,
  #sib-form select,
  #sib-form input {
    color: #000;
  }
  #sib-form input::placeholder {
    color: #000;
    padding-left: 10px;
  }
  .sib-text-form-block p {
    font-size: 13px !important;
  }
  .sib-optin.sib-form-block {
    padding: 0 40px;
  }
</style>
